import React from 'react';

import styles from '../FreeTrial.module.scss';

import useFreeTrialAssets from '../useFreeTrialAssets';
import FadeIn from 'src/components/FadeIn';
import { ResponsiveBackgroundImage } from 'src/components/ResponsiveBackgroundImage';
import { DownArrow } from 'src/components/DownArrow';
import { scrollToElementById } from 'src/helpers/scrollToElementById';
import { JoinCta } from 'src/components/JoinCta';

export const FreeTrialHero: React.FC = () => {
  const assets = useFreeTrialAssets();
  const { hero } = assets;

  const handleDownArrowClick = () => {
    scrollToElementById('benefits-boxes-free-trial');
  };

  return (
    <div className={styles.freeTrialHero} data-cy="free-trial-hero">
      <FadeIn duration={0.3}>
        <ResponsiveBackgroundImage
          className={styles.freeTrialHero__backgroundContainer}
          bgClassName={styles.freeTrialHero__background}
          desktopUrl={hero.images[0].url}
          tabletUrl={hero.images[1].url}
          mobileUrl={hero.images[2].url}
        >
          <div className="container container_center">
            <div className={styles.freeTrialHero__container}>
              <FadeIn duration={0.4} up>
                <div className={styles.freeTrialHero__copy}>
                  <h2
                    className={`${styles.freeTrialHero__title} no-padding color-white`}
                    data-cy="free-trial-hero-title"
                  >
                    <span>{hero.title['text-0']}</span>
                    <span className={`${styles.freeTrialunderline} ${styles.freeTrialUnderlineHero}`}>
                      {hero.title['em-1']}
                    </span>
                    <span>{hero.title['text-2']}</span>
                    <span className={`${styles.freeTrialunderline} ${styles.freeTrialUnderlineHero}`}>
                      {hero.title['em-3']}
                    </span>
                  </h2>
                  <p className={styles.freeTrialHero__description} data-cy="free-trial-hero-description">
                    {hero.description['text-0']} <strong>{hero.description['strong-1']}</strong>
                  </p>
                  <JoinCta
                    className={`${styles.freeTrialLink} button button__gradient`}
                    testid="free-trial-hero-link"
                    disclaimerClassName={styles.freeTrialDisclaimer}
                    disclaimerTestId="free-trial-hero-disclaimer"
                  />
                </div>
              </FadeIn>
              <div className={styles.freeTrialHero__downArrow}>
                <DownArrow onClick={handleDownArrowClick} />
              </div>
            </div>
          </div>
        </ResponsiveBackgroundImage>
      </FadeIn>
    </div>
  );
};
