import marked from 'marked';
import { getAltText } from 'src/helpers/getAltText';

type Image = {
  alt: string;
  url: string;
};

export const parseImages = (markdown: string): Image[] => {
  const tokens = marked.lexer(markdown);
  const images: Image[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          images.push({
            alt: getAltText(token.tokens[0].text),
            url: (token.tokens[0] as marked.Tokens.Image).href,
          });
        }
        break;
      }
    }
  }

  return images;
};

export const parseTokenType = (markdown: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tokens = (marked.lexer(markdown)[0] as marked.Token).tokens;
  const text = {};
  tokens.forEach((token, index) => {
    text[`${token.type}-${index}`] = token.text;
  });
  return text;
};

export const getEmMarkup = (raw: string): string => {
  // check for multiple `*` occurances
  const match = raw.match(/\*[^*]+\*/);
  if (match) {
    return raw.replace('*', '<em>').replace('*', '</em>');
  }
  return raw;
};

export const getStrongMarkup = (raw: string): string => {
  return raw.replace('__', '<strong>').replace('__', '</strong>');
};

export const parseList = (markdown: string) => {
  const tokens = marked.lexer(markdown);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const items = tokens[0].items.map((item) => item.text);
  return items;
};
