import React from 'react';
import styles from './ResponsiveBackgroundImage.module.scss';
import cx from 'classnames';

import useResponsiveBackgroundImage from 'src/components/useResponsiveBackgroundImage';
import { ResponsiveBackgroundProps } from './ResponsiveBackgroundImage.types';

export const ResponsiveBackgroundImage: React.FC<ResponsiveBackgroundProps> = ({
  ariaLabel,
  children,
  className = '',
  bgClassName = '',
  desktopUrl,
  tabletUrl,
  mobileUrl,
}) => {
  const style = useResponsiveBackgroundImage({
    desktop: desktopUrl,
    tablet: tabletUrl,
    mobile: mobileUrl,
  });

  return (
    <div className={cx(styles.responsiveBgImage, className)}>
      <span
        className={cx(styles.responsiveBgImage__background, bgClassName)}
        style={style}
        role={ariaLabel ? 'img' : undefined}
        aria-label={ariaLabel ? ariaLabel : undefined}
        data-cy="responsive-background-image"
      ></span>
      {children}
    </div>
  );
};
