import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { parseImages, parseTokenType, parseList, getEmMarkup } from 'src/helpers/parseMarkdown';
import { parseFAQs } from 'src/components/checkout/useFAQs';

const useFreeTrialAssets = () => {
  const { t, ready } = useTranslation();

  return useMemo(() => {
    return {
      hero: {
        title: parseTokenType(t('free-trial.hero.title')),
        description: parseTokenType(t('free-trial.hero.description')),
        images: parseImages(t('free-trial.hero.images')),
        disclaimer: t('free-trial.hero.disclaimer'),
      },
      cta: {
        join: t('free-trial.cta.join'),
      },
      boxes: parseList(t('landing-page.benefits.boxes.free-trial')).map((item) => getEmMarkup(item)),
      banner: {
        title: parseTokenType(t('free-trial.banner.title')),
        description: t('free-trial.banner.description'),
        images: parseImages(t('free-trial.banner.images')),
        disclaimer: t('free-trial.banner.disclaimer'),
      },
      faq: {
        title: t('faq.title'),
        items: parseFAQs(t('free-trial.faq.list')),
      },
    };
  }, [ready]);
};

export default useFreeTrialAssets;
