import React from 'react';

type Element = {
  text: string;
  href?: string;
  em?: boolean;
};

type AccordionProps = {
  summary: string;
  details: Element[][];
  id: string;
  onClick?: (text: string) => void;
};

const Accordion: React.FC<AccordionProps> = ({ summary, details, id, onClick }) => {
  const handleClick = () => {
    onClick(summary);
  };

  return (
    <div className="accordion__tab">
      <input
        aria-labelledby={`${id}-label`}
        aria-controls={`${id}-content`}
        aria-checked="false"
        className="accordion__tab_checkbox"
        type="checkbox"
        id={id}
        onClick={handleClick}
      />
      <label className="accordion__tab_label text-heading_4" htmlFor={id} id={`${id}-label`}>
        {summary}
        <svg className="accordion__tab_label__icon icon icon_2x-large icon_blue" aria-hidden="true">
          <use href="#48-more" />
        </svg>
      </label>
      <div aria-hidden="true" className="accordion__tab_content" id={`${id}-content`}>
        {details.map((paragraph, i) => (
          <p key={i}>
            {paragraph.map(({ text, href, em }, index) => {
              if (href) {
                return <a href={href} key={index} dangerouslySetInnerHTML={{ __html: text }} />;
              } else if (em) {
                return <em key={index} dangerouslySetInnerHTML={{ __html: text }} />;
              } else {
                return <span key={index} dangerouslySetInnerHTML={{ __html: text }} />;
              }
            })}
          </p>
        ))}
      </div>
      <div className="accordion__divider divider" />
    </div>
  );
};

export default Accordion;
