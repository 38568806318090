import marked from 'marked';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import linkVariables from 'src/helpers/linkVariables';

export type Element = {
  text: string;
  href?: string;
};

type QnA = {
  q: string;
  a: Element[][];
};

export const parseFAQs = (markdown: string): QnA[] => {
  const faqs: QnA[] = [];
  const tokens = marked.lexer(markdown);
  for (const token of tokens) {
    switch (token.type) {
      case 'heading':
        faqs.push({ q: token.text, a: [] });
        break;
      case 'paragraph':
        const p = token.tokens
          .filter((t) => t.type === 'text' || t.type === 'link' || t.type === 'em')
          .map((t) => {
            const element: Element = {
              text: t['text'],
            };
            if (t['href']) {
              element.href = t['href'];
            }
            if (t.type === 'em') {
              element.em = true;
            }
            return element;
          }) as Element[];
        faqs[faqs.length - 1].a.push(p);
    }
  }
  return faqs;
};

const useFAQs = () => {
  const { t, ready } = useTranslation();

  return useMemo(() => {
    const markdown = t('checkout.faq.list.fullSingleTier', linkVariables());
    return parseFAQs(markdown);
  }, [ready]);
};

export default useFAQs;
