import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Benefit } from 'src/components/useLandingPageAssets';
import FadeIn from 'src/components/FadeIn';
import Arrow from './icons/right-48.svg';

import { useTrackingContext } from 'src/components/TrackingContext';

import useFreeTrialAssets from 'src/components/FreeTrial/useFreeTrialAssets';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { Translate } from './Translate';

type LinksProps = {
  benefits: Benefit[];
};

const scrollToBenefit = (benefit: Benefit) => {
  const target = document.getElementById(benefit.id);
  const winOff = window.pageYOffset || document.documentElement.scrollTop;
  const offset = target.getBoundingClientRect().top - (window.innerWidth >= 480 ? 120 : 60);
  const top = Math.floor(offset + winOff);
  window.scrollTo({ top, behavior: 'smooth' });
};

export const BenefitBoxes: React.FC<LinksProps> = ({ benefits }) => {
  const listWrapper = useRef(null);
  const list = useRef(null);
  const listItem = useRef(null);
  const [scrollPos, setScrollPos] = useState(0);
  const { trackInteraction } = useTrackingContext();

  const { isEligibleForFreeTrial } = useFreeTrial();
  const assets = useFreeTrialAssets();
  const { boxes } = assets;

  useEffect(() => {
    listWrapper.current.addEventList;
  }, []);

  const handleBoxClick = (benefit: Benefit) => {
    scrollToBenefit(benefit);
    trackInteraction('click', benefit.name);
  };

  const handleScroll = (evt) => {
    setScrollPos(evt.target.scrollLeft);
  };

  const onPrevClick = () => {
    const page = Math.ceil(listWrapper.current.scrollLeft / (listItem.current.offsetWidth + 24)) - 1;
    let newScrollPos = page * (listItem.current.offsetWidth + 24);
    if (listWrapper.current.scrollLeft - newScrollPos <= 100) {
      newScrollPos = (page - 1) * (listItem.current.offsetWidth + 24);
    }

    listWrapper.current.scrollLeft = newScrollPos;
  };

  const onNextClick = () => {
    const page = Math.floor(listWrapper.current.scrollLeft / (listItem.current.offsetWidth + 24)) + 1;
    const newScrollPos = page * (listItem.current.offsetWidth + 24);
    listWrapper.current.scrollLeft = newScrollPos;
  };

  return (
    <div className="benefit-boxes" data-cy="top-benefit-boxes">
      <div className="benefit-boxes__list-wrap" ref={listWrapper} onScroll={handleScroll}>
        <FadeIn duration={0.3}>
          <div className="benefit-boxes__boxes-list" ref={list}>
            {benefits.map((b, i) => (
              <span
                key={b.id}
                ref={listItem}
                className={cx('benefit-boxes__card benefit-boxes__card--narrow', {
                  'benefit-boxes__card--free-trial': isEligibleForFreeTrial,
                })}
                data-cy={`box-link-to-${b.id}`}
                onClick={() => handleBoxClick(b)}
              >
                {isEligibleForFreeTrial ? null : <span className="number">0{i + 1}</span>}
                <img className="icon" src={b.svg} alt="icon" />
                <div className="name">{b.name}</div>
                {isEligibleForFreeTrial && (
                  <p className="benefit-boxes__description">
                    <Translate resourceKey={boxes[i]} useHtml />
                  </p>
                )}
              </span>
            ))}
          </div>
        </FadeIn>
      </div>
      <div className="benefit-boxes__nav" data-cy="benefit-scroll-buttons">
        <button onClick={onPrevClick} disabled={scrollPos === 0} aria-label="previous">
          <Arrow />
        </button>
        <button
          onClick={onNextClick}
          disabled={listWrapper.current?.offsetWidth + scrollPos >= list.current?.offsetWidth - 24}
          aria-label="next"
        >
          <Arrow />
        </button>
      </div>
    </div>
  );
};
