import { useMemo } from 'react'
import { useMedia } from 'react-use';

type BackgroundImages = {
  mobile: string;
  tablet: string;
  desktop: string;
};

const useResponsiveBackgroundImage = (images: BackgroundImages) => {
  const tablet = useMedia('(max-width: 1000px)');
  const mobile = useMedia('(max-width: 480px)');
  const image = mobile ? images.mobile : (tablet ? images.tablet : images.desktop);
  return useMemo(() => (
    { backgroundImage: `url('${image}')` }
  ), [images, tablet, mobile]);
};

export default useResponsiveBackgroundImage;
