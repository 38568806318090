import React, { useEffect, useState } from 'react';
import { useTreatments } from '@splitsoftware/splitio-react';
import styles from '../FreeTrial.module.scss';
import useFreeTrialAssets from '../useFreeTrialAssets';
import { Accordion } from 'src/components/designSystems/Accordion';

import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { useTrackingContext } from 'src/components/TrackingContext';
import { formatTestExperience } from 'src/helpers/formatTestExperience';

export const FreeTrialFAQ: React.FC = () => {
  const assets = useFreeTrialAssets();
  const { faq } = assets;
  const splitIsReady = isReady();
  const { trackInteraction } = useTrackingContext();
  const [experiments, setExperiments] = useState([]);

  const treatments = useTreatments([Split.ExperimentAlreadyMemberCta, Split.ExperimentMonthlyPricing]);
  const hasAlreadyMemberExperiment = treatments[Split.ExperimentAlreadyMemberCta].treatment === Treatment.On;
  const hasMonthlyPriceExperiment = treatments[Split.ExperimentMonthlyPricing].treatment === Treatment.On;
  const { isEligibleForFreeTrial } = useFreeTrial();

  useEffect(() => {
    if (splitIsReady) {
      const monthlyExperiment = formatTestExperience(Split.ExperimentMonthlyPricing, hasMonthlyPriceExperiment);
      const alreadyMemberExperiment = formatTestExperience(
        Split.ExperimentAlreadyMemberCta,
        hasAlreadyMemberExperiment
      );
      const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);
      setExperiments([monthlyExperiment, freeTrialExperiment, alreadyMemberExperiment]);
    }
  }, [splitIsReady]);

  const handleClick = (text: string) => {
    trackInteraction('click', text, {
      test_experience: experiments,
    });
  };

  return (
    <div className={styles.freeTrialFAQ}>
      <h3 className={`${styles.freeTrialFAQ__title} text-display_3`} data-cy="free-trial-faq-title">
        {faq.title}
      </h3>
      <div className="accordion" data-toggle="accordion" aria-label="Accordion Control Group Buttons">
        <div className="accordion__tabs" data-cy="faq-list">
          {faq.items.map(({ q, a }, i) => (
            <Accordion summary={q} details={a} id={`faq-${i}`} key={`faq-${i}`} onClick={handleClick} />
          ))}
        </div>
      </div>
    </div>
  );
};
