import React, { useState, useRef, useEffect } from 'react';
import { useMedia } from 'react-use';

import { ContentfulDiscount } from 'src/models/discounts';

import { getPartnerThumbnail } from 'src/helpers/contentfulHelper';
import FadeIn from 'src/components/FadeIn';
import useBodyClassInjector from 'src/hooks/useBodyClassInjector';
import { useTrackingContext } from 'src/components/TrackingContext';

type DiscountProps = {
  discount: ContentfulDiscount;
};

type DiscountsProps = {
  discounts: ContentfulDiscount[];
};

type SliderProps = {
  children: JSX.Element | JSX.Element[];
  disabled: boolean;
};

const DiscountsSlider: React.FC<SliderProps> = ({ children, disabled }) => {
  const scrollLength = 1;
  const { setBodyClasses, clearBodyClasses } = useBodyClassInjector();
  const discountsWrapper = useRef(null);
  const scrollTimer = useRef(null);
  const leftPos = useRef(0);
  const cloneLeftPos = useRef(0);
  const [isDragging, setIsDragging] = useState(false);
  const [sliderPos, setSliderPos] = useState({ left: leftPos.current, cloneLeft: cloneLeftPos.current });
  const [prevTouchEventX, setPrevTouchEventX] = useState(null);

  const setPosition = (left, cloneLeft) => {
    const slideWidth = discountsWrapper.current.getBoundingClientRect().width;
    let offset = 0;
    let cloneOffset = 0;

    if (left > slideWidth) {
      offset = left - slideWidth;
      left = -slideWidth + offset;
    }

    if (left < -slideWidth) {
      offset = Math.abs(left) - slideWidth;
      left = slideWidth - offset;
    }

    if (cloneLeft < 2 * -slideWidth) {
      cloneOffset = Math.abs(cloneLeft) - 2 * slideWidth;
      cloneLeft = -cloneOffset;
    }

    if (cloneLeft > 0) {
      cloneOffset = Math.abs(cloneLeft) - 2 * slideWidth;
      cloneLeft = 2 * -slideWidth + cloneOffset;
    }

    leftPos.current = left;
    cloneLeftPos.current = cloneLeft;

    setSliderPos({ left, cloneLeft });
  };

  const startScroll = () => {
    const left = leftPos.current - scrollLength;
    const cloneLeft = cloneLeftPos.current - scrollLength;
    setPosition(left, cloneLeft);
    scrollTimer.current = requestAnimationFrame(startScroll);
  };

  const stopScroll = () => {
    cancelAnimationFrame(scrollTimer.current);
  };

  useEffect(() => {
    if (disabled) return;
    startScroll();
    return stopScroll;
  }, [disabled]);

  const handleMouseEnter = () => {
    if (disabled) return;
    stopScroll();
  };

  const handMouseLeave = () => {
    if (disabled) return;
    startScroll();
    setIsDragging(false);
  };

  const handleMouseDown = () => {
    if (disabled) return;
    stopScroll();
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    if (disabled) return;
    setIsDragging(false);
  };

  const handleTouchStart = () => {
    // setting no-overscroll on body element to prevent 'scroll bounce' when scrolling marquee on mobile
    setBodyClasses(['no-overscroll']);
    setIsDragging(true);
  };

  const handleTouchEnd = () => {
    clearBodyClasses();
    setIsDragging(false);
    setPrevTouchEventX(null);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;

    const touchX = Math.floor(e.changedTouches[0].clientX);

    if (!prevTouchEventX) {
      setPrevTouchEventX(touchX);
    } else {
      const diff = touchX - prevTouchEventX;
      const left = leftPos.current + diff;
      const cloneLeft = cloneLeftPos.current + diff;
      setPosition(left, cloneLeft);
      setPrevTouchEventX(touchX);
    }
  };

  const handleDrag = (e) => {
    if (disabled) return;
    if (!isDragging) return;
    const left = leftPos.current + e.movementX;
    const cloneLeft = cloneLeftPos.current + e.movementX;
    setPosition(left, cloneLeft);
  };

  return (
    <div
      className={`discount-marquee__slider ${disabled ? 'stopped' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleDrag}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      <div
        ref={discountsWrapper}
        className="discount-marquee__slide"
        style={{ transform: `translateX(${sliderPos.left}px)` }}
      >
        {children}
      </div>
      <div className="discount-marquee__slide" style={{ transform: `translateX(${sliderPos.cloneLeft}px)` }}>
        {children}
      </div>
    </div>
  );
};

const DiscountCard: React.FC<DiscountProps> = ({ discount }) => {
  const { trackInteraction } = useTrackingContext();

  const handleClick = () => {
    const partnerDiscount = `${discount.partner.title} | ${discount.shortDescription}`;
    trackInteraction('carousel_click', partnerDiscount);
  };

  return (
    <div className="discount-marquee__card" onClick={handleClick}>
      <img src={getPartnerThumbnail(discount.partner)} alt={discount?.partner?.thumbnail?.image?.description} />
      <div className="discount-marquee__card__discount">
        <h6>{discount.shortDescription}</h6>
      </div>
      <div className="discount-marquee__card__info">
        <p>{discount.partner.title}</p>
        <span>{discount.serviceObject}</span>
      </div>
    </div>
  );
};

const DiscountMarquee: React.FC<DiscountsProps> = ({ discounts }) => {
  const isDesktopOrLaptop = useMedia('(min-width: 1025px)');

  return (
    <FadeIn duration={0.3}>
      <div className="discount-marquee" data-cy="hover-discount-marquee">
        <DiscountsSlider disabled={!isDesktopOrLaptop}>
          {discounts.map((d) => (
            <DiscountCard key={d.id} discount={d} />
          ))}
        </DiscountsSlider>
      </div>
    </FadeIn>
  );
};

export default DiscountMarquee;
