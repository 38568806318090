import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { App } from 'src/components/useLandingPageAssets';
import FadeIn from 'src/components/FadeIn';
import googlePlayButton from '../../static/svg/google-play.svg';
import appStoreButton from '../../static/svg/app-store.svg';

export const BenefitsApp: React.FC<App> = ({ title, subtitle, googleUrl, appleUrl }) => {
  const isMobile = useMedia('(max-width: 767px)');
  const [titleClass, setTitleClass] = useState<string>('text-display_2');

  useEffect(() => {
    setTitleClass(isMobile ? 'text-display_3' : 'text-display_2');
  }, [isMobile]);

  return (
    <div className="benefits-page__learn-more-wrap" data-cy="benefits-page-cta">
      <FadeIn duration={0.4} up>
        <h2 className={titleClass} data-cy="title">
          {title}
        </h2>
        <h2 className={`${titleClass} benefits-page__gradient-heading`} data-cy="subtitle">
          {subtitle}
        </h2>
        <div className="benefits-page__learn-more">
          <a href={googleUrl} className="benefits-page__app-link" data-cy="google-play">
            <img src={googlePlayButton} alt="Get it on Google Play" />
          </a>
          <a href={appleUrl} className="benefits-page__app-link" data-cy="app-store">
            <img src={appStoreButton} alt="Download on the App Store" />
          </a>
        </div>
      </FadeIn>
    </div>
  );
};
