import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import marked from 'marked';

import makeSvgSingleLine from '../components/makeSvgSingleLine';
import svgDataURL from '../helpers/svgDataURL';
import { toContentfulCDN } from 'src/helpers/toContentfulCDN';

// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/4ZvyqR6pUhXiVnZ5WRC5rc
export type CTA = {
  title: string;
  subtitle: string;
  action: string;
  images?: string[];
};

type Paragraph = {
  // The leading bold text
  lead?: string;

  // the detail text
  content: string;
};

export type More = {
  image: string;
  contents: Paragraph[];
  heading?: string;
};

// https://app.contentful.com/spaces/w61n0jg6cdlz/entries/696y59yhefnXoM08KGTZ1H
export type Benefit = {
  id: string; //
  name: string;
  svg: string;
  title: string;
  subtitle: string;
  action: string;
  more: More;
  images?: string[];
  trackAs: string;
};

// https://app.contentful.com/spaces/w61n0jg6cdlz/entries/5UHZjdtE2GsJnPpUfj1Axk
export type App = {
  images?: string[];
  title: string;
  subtitle: string;
  googleUrl: string;
  appleUrl: string;
};

export type Assets = {
  cats: CTA[];
  benefits: Benefit[];
  freeTrialBenefits: Benefit[];
  marketplaceModalBenefits: Benefit[];
  support: Benefit;
  app: App;
};

export const parseCTAs = (markdown: string): CTA[] => {
  const tokens = marked.lexer(markdown);
  const ctas: CTA[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        ctas.push({ title: token.text, subtitle: '', action: '' });
        break;
      }
      case 'list': {
        ctas[ctas.length - 1].subtitle = token.items[0].text;
        ctas[ctas.length - 1].action = token.items[1].text;
        break;
      }
      case 'paragraph': {
        const image = (token.tokens[0] as marked.Tokens.Image).href;
        if (!ctas[ctas.length - 1].images) {
          ctas[ctas.length - 1].images = [];
        }
        ctas[ctas.length - 1].images.push(toContentfulCDN(image));
        break;
      }
      default: {
        break;
      }
    }
  }
  return ctas;
};

const parseApp = (markdown: string) => {
  const tokens = marked.lexer(markdown);
  const app: App = {
    title: null,
    subtitle: null,
    googleUrl: null,
    appleUrl: null,
    images: [],
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        app.title = token.text;
        break;
      }
      case 'list': {
        app.subtitle = token.items[0].text;
        app.googleUrl = token.items[1].text;
        app.appleUrl = token.items[2].text;
        break;
      }
      case 'paragraph': {
        const image = (token.tokens[0] as marked.Tokens.Image).href;
        app.images.push(toContentfulCDN(image));
        break;
      }
      default: {
        break;
      }
    }
  }
  return app;
};

const parseMoreParagraph = (item: marked.Tokens.ListItem): Paragraph => {
  const m = item.text.match(/^\*\*(.+)\*\*\s*(.+)\s*$/);
  if (m) {
    return {
      lead: m[1],
      content: m[2],
    };
  }
  return {
    content: item.text,
  };
};

export const parseBenefits = (markdown: string, hasModalHeading = false): Benefit[] => {
  const tokens = marked.lexer(makeSvgSingleLine(markdown));
  const benefits: Benefit[] = [];

  let benefit: Benefit;
  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        const name = token.text;
        benefits.push({
          id: null,
          name,
          svg: null,
          title: null,
          subtitle: null,
          action: null,
          more: null,
          trackAs: null,
        });
        break;
      }
      case 'paragraph': {
        benefit = benefits[benefits.length - 1];
        if (token.text.startsWith('<svg')) {
          benefit.svg = svgDataURL(token.text);
        } else {
          if (!benefit.images) {
            benefit.images = [];
          }
          const image = (token.tokens[0] as marked.Tokens.Image).href;
          benefit.images.push(toContentfulCDN(image));
        }
        break;
      }
      case 'list': {
        benefit = benefits[benefits.length - 1];
        benefit.trackAs = token.items[0]?.text;
        benefit.id = token.items[0]?.text;
        benefit.title = token.items[1]?.text;
        benefit.subtitle = token.items[2]?.text;
        benefit.action = (token.items[3].tokens[0] as marked.Tokens.Text).text;
        const items = (token.items[3].tokens[1] as marked.Tokens.List).items;
        const image = ((items[0].tokens[0] as marked.Tokens.Text).tokens[0] as marked.Tokens.Image).href;
        items.shift();
        benefit.more = {
          image,
          contents: null,
        };
        if (hasModalHeading) {
          benefit.more.heading = items[0].text;
          items.shift();
        }
        benefit.more.contents = items.map(parseMoreParagraph);
        break;
      }
      default: {
        break;
      }
    }
  }
  return benefits;
};

const useLandingPageAssets = () => {
  const { t, ready } = useTranslation();

  return useMemo<Assets>((): Assets => {
    return {
      cats: parseCTAs(t('landing-page.framework.fullSingleTier')),
      benefits: parseBenefits(t('landing-page.benefits.fullSingleTier')),
      freeTrialBenefits: parseBenefits(t('landing-page.benefits.free-trial')),
      marketplaceModalBenefits: parseBenefits(t('landing-page.benefits.marketplace-modal'), true),
      support: parseBenefits(t('landing-page.benefit.support'))[0],
      app: parseApp(t('landing-page.benefit.app')),
    };
  }, [ready]);
};

export default useLandingPageAssets;
