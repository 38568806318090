import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { CTA } from 'src/components/useLandingPageAssets';
import FadeIn from 'src/components/FadeIn';
import { JoinCta } from 'src/components/JoinCta';

const LandingPageCta: React.FC<CTA> = ({ title, subtitle }) => {
  const isMobile = useMedia('(max-width: 767px)');
  const [titleClass, setTitleClass] = useState<string>('text-display_2');

  useEffect(() => {
    setTitleClass(isMobile ? 'text-display_3' : 'text-display_2');
  }, [isMobile]);

  return (
    <div className="benefits-page-cta" data-cy="benefits-page-cta">
      <FadeIn duration={0.4} up>
        <h2 className={titleClass} data-cy="title">
          {title}
        </h2>
        <h2 className={`${titleClass} benefits-page__gradient-heading`} data-cy="subtitle">
          {subtitle}
        </h2>
        <JoinCta testid="join-membership" disclaimerClassName="landing-page-disclaimer" />
      </FadeIn>
    </div>
  );
};

export default LandingPageCta;
