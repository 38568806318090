import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { SiteDisclaimer, Disclaimer } from 'src/components/Disclaimers';
import useLandingPageAssets from 'src/components/useLandingPageAssets';
import DiscountMarquee from 'src/components/DiscountMarquee';
import { BenefitBoxes } from 'src/components/BenefitBoxes';
import BenefitsLearnMore from 'src/components/BenefitsLearnMore';
import useBenefitsPageQueries from 'src/components/benefits/useBenefitsPageQueries';
import { BenefitsMagazine } from 'src/components/BenefitsMagazine';
import { BenefitsApp } from 'src/components/BenefitsApp';
import LandingPageCta from 'src/components/LandingPageCta';
import { useAutoLogin } from 'src/hooks/useAutoLogin';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';
import { navigate } from 'gatsby-link';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { AlreadyMemberButton } from 'src/components/already-member-button';

import FadeIn from 'src/components/FadeIn';
import useResponsiveBackgroundImage from 'src/components/useResponsiveBackgroundImage';

import { FreeTrialHero, FreeTrialBanner, FreeTrialFAQ } from 'src/components/FreeTrial';

import discounts from 'src/models/contentful-discounts.json';

import { useTreatments } from '@splitsoftware/splitio-react';
import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import LoadingContainer from 'src/components/LoadingContainer';
import { ResponsiveBackgroundImage } from 'src/components/ResponsiveBackgroundImage';
import { useTracking } from 'src/components/TrackingContext/useTracking';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { formatTestExperience } from 'src/helpers/formatTestExperience';
import { MainFooter } from 'src/features/footer';

const Index: React.FC<PageProps> = () => {
  usePreventAgentAccess();
  useAutoLogin();
  const [experiments, setExperiments] = useState([]);
  const assets = useLandingPageAssets();
  const support = assets.support;
  const app = assets.app;
  const { membership, ready } = useBenefitsPageQueries();
  const splitIsReady = isReady();
  const treatments = useTreatments([
    Split.ExperimentAlreadyMemberCta,
    Split.ExperimentMonthlyPricing,
    Split.FeatureMarketplaceCopyUpdate,
  ]);
  const hasAlreadyMemberExperiment = treatments[Split.ExperimentAlreadyMemberCta].treatment === Treatment.On;
  const hasMonthlyPriceExperiment = treatments[Split.ExperimentMonthlyPricing].treatment === Treatment.On;
  const hasMarketplaceCopyFeature = treatments[Split.FeatureMarketplaceCopyUpdate].treatment === Treatment.On;
  const { isEligibleForFreeTrial, freeTrialIsReady } = useFreeTrial();
  const { t } = useTranslation();

  const benefits = hasMarketplaceCopyFeature ? assets.marketplaceModalBenefits : assets.benefits;
  const [offers, roadside, marketplace, experiences, valuation, content] = benefits;

  const headingStyle = useResponsiveBackgroundImage({
    desktop: assets.cats[0].images[0],
    tablet: assets.cats[0].images[1],
    mobile: assets.cats[0].images[2],
  });

  const roadsideStyle = useResponsiveBackgroundImage({
    desktop: roadside.images[0],
    tablet: roadside.images[1],
    mobile: roadside.images[2],
  });

  if (ready && membership) {
    navigate('/benefits/');
  }

  useEffect(() => {
    if (splitIsReady && freeTrialIsReady) {
      const monthlyExperiment = formatTestExperience(Split.ExperimentMonthlyPricing, hasMonthlyPriceExperiment);
      const alreadyMemberExperiment = formatTestExperience(
        Split.ExperimentAlreadyMemberCta,
        hasAlreadyMemberExperiment
      );
      const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);
      setExperiments([monthlyExperiment, freeTrialExperiment, alreadyMemberExperiment]);
    }
  }, [splitIsReady, freeTrialIsReady]);

  useTracking('homepage_view', experiments);

  return (
    <>
      <Helmet>
        <title>{t('head.title.drivers-club')}</title>
        <meta name="description" content={t('head.meta-description.drivers-club')} />
        <meta name="robots" content="all" />
      </Helmet>
      <div className="benefits-page">
        {freeTrialIsReady ? (
          <>
            {isEligibleForFreeTrial ? (
              <>
                <FreeTrialHero />
                <div className="container container_center">
                  <div id="benefits-boxes-free-trial" className="benefits-boxes-free-trial">
                    <BenefitBoxes benefits={assets.freeTrialBenefits} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="benefits-page__hero" data-cy={'test'}>
                  <span className="benefits-page__background" style={headingStyle}></span>
                  <div className="benefits-page__heading">
                    <div className="container container_center">
                      <div className="benefits-page-hero">
                        <LandingPageCta {...assets.cats[0]} />
                      </div>
                      <FadeIn duration={0.4} up>
                        <div className="benefits-page__login-header">
                          <AlreadyMemberButton theme="light" />
                        </div>
                      </FadeIn>
                    </div>
                  </div>
                </div>
                <div className="container container_center">
                  <BenefitBoxes benefits={assets.benefits} />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <ResponsiveBackgroundImage desktopUrl="" tabletUrl="" mobileUrl="">
              <div className="position-absolute-center">
                <LoadingContainer />
              </div>
            </ResponsiveBackgroundImage>
            <div style={{ height: '300px' }}></div>
          </>
        )}

        <div className="container container_center">
          <BenefitsLearnMore benefit={offers} className="benefits-page__content-offers" />
        </div>

        <DiscountMarquee discounts={discounts} />

        <FadeIn duration={0.3}>
          <div className="benefits-page__roadside" data-cy="roadside-section">
            <span className="benefits-page__roadside-background" style={roadsideStyle}></span>
            <div className="container container_center">
              <BenefitsLearnMore benefit={roadside} buttonClassName="button__cta--secondary" />
            </div>
          </div>
        </FadeIn>

        <div className="container container_center benefits-page__container-no-padding-mob">
          <div className="benefits-page__section benefits-page__section--reverse">
            <div className="benefits-page__section-inner benefits-page__section-inner--left">
              <BenefitsLearnMore benefit={marketplace} />
            </div>
            <figure>
              <FadeIn duration={0.3}>
                <img alt="vehicle-insights" className="benefits-page__image" src={marketplace.images[0]} />
              </FadeIn>
            </figure>
          </div>

          <div className="benefits-page__section">
            <div className="benefits-page__section-inner benefits-page__section-inner--right">
              <BenefitsLearnMore benefit={experiences} />
            </div>
            <figure>
              <FadeIn duration={0.3}>
                <img alt="vehicle-insights" className="benefits-page__image" src={experiences.images[0]} />
              </FadeIn>
            </figure>
          </div>

          <div className="benefits-page__section benefits-page__section--reverse">
            <div className="benefits-page__section-inner benefits-page__section-inner--left">
              <BenefitsLearnMore benefit={valuation} />
            </div>
            <figure>
              <FadeIn duration={0.3}>
                <img alt="vehicle-insights" className="benefits-page__image" src={valuation.images[0]} />
              </FadeIn>
            </figure>
          </div>

          <div className="benefits-page__section">
            <div className="benefits-page__section-inner benefits-page__section-inner--right">
              <BenefitsLearnMore benefit={support} />
            </div>
            <figure>
              <FadeIn duration={0.3}>
                <img alt="vehicle-insights" className="benefits-page__image" src={support.images[0]} />
              </FadeIn>
            </figure>
          </div>

          <div className="benefits-page__section benefits-page__section--reverse">
            <div className="benefits-page__section-inner benefits-page__section-inner--left">
              <BenefitsApp {...app} />
            </div>
            <figure>
              <FadeIn duration={0.3}>
                <img alt="vehicle-insights" className="benefits-page__image" src={app.images[0]} />
              </FadeIn>
            </figure>
          </div>
        </div>

        <div className="container container_center">
          <BenefitsLearnMore benefit={content} className="benefits-page__original-content" />
        </div>

        <BenefitsMagazine />
        {splitIsReady ? (
          <>
            {isEligibleForFreeTrial ? (
              <FreeTrialBanner />
            ) : (
              <div className="container container_center">
                <div className="benefits-page__bottom-blurb">
                  <LandingPageCta {...assets.cats[1]} />
                </div>
              </div>
            )}
          </>
        ) : (
          <LoadingContainer />
        )}

        {isEligibleForFreeTrial && (
          <div className="container container_center">
            <FreeTrialFAQ />
          </div>
        )}
      </div>

      <footer className="container container_center">
        <SiteDisclaimer hasSavings />
        {hasMarketplaceCopyFeature && <Disclaimer resource={'site-disclaimer.marketplace'} />}
      </footer>

      <MainFooter />
    </>
  );
};

export default Index;
