import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { Button, Modal } from '@hagerty/react-components';
import { navigate } from 'gatsby';

import FadeIn from 'src/components/FadeIn';

import { Benefit } from 'src/components/useLandingPageAssets';
import { useTrackingContext } from 'src/components/TrackingContext';
import useJoinTheClubLink from 'src/hooks/useJoinTheClubLink';
import { Translate } from 'src/components/Translate';

type BenefitProps = {
  benefit: Benefit;
  className?: string;
  buttonClassName?: string;
  testExperience?: string[];
};

const BenefitsLearnMore: React.FC<BenefitProps> = ({ benefit, className, buttonClassName, testExperience }) => {
  const isMobile = useMedia('(max-width: 767px)');
  const [titleClass, setTitleClass] = useState<string>('text-display_2');
  const linkTo = useJoinTheClubLink();

  useEffect(() => {
    setTitleClass(isMobile ? 'text-display_3' : 'text-display_2');
  }, [isMobile]);
  const { trackModalView } = useTrackingContext();

  const handleOpen = () => {
    trackModalView(benefit.title, testExperience);
  };

  const handleJoin = () => {
    navigate(linkTo);
  };

  const learnMoreTitle = benefit.more.heading || benefit.title;

  return (
    <div className={`benefits-page__learn-more-wrap ${className || ''}`} data-cy={benefit.id}>
      <FadeIn duration={0.4} up>
        <h2 className={titleClass} data-cy="title" id={benefit.id}>
          {benefit.title}
        </h2>
        <h2 className={`${titleClass} benefits-page__gradient-heading`} data-cy="subtitle">
          {benefit.subtitle}
        </h2>
        <div className="benefits-page__learn-more">
          <Button
            className={`button button__cta ${buttonClassName || ''}`}
            testId="cta-modal-button"
            id={`${benefit.id}-open`}
            onClick={handleOpen}
          >
            {benefit.action}
          </Button>
        </div>
      </FadeIn>
      <Modal id={`${benefit.id}-open`} size={'Small'} isAnimated>
        <img className="learn-more__image" src={benefit.more.image} alt="banner" data-cy="header" />
        <div className="learn-more__body">
          <div className="html-dom-modal__section-title" data-cy="cta-modal__title">
            {learnMoreTitle}
          </div>
          <div className={`html-dom-modal__section-text ${benefit.trackAs}`} data-cy="content">
            {benefit.more.contents.map((e, i) => (
              <p key={i} data-cy={i}>
                {e.lead && <strong>{e.lead}</strong>} <span>{e.content}</span>
              </p>
            ))}
          </div>
          <div className="inset-m" />
          <Button className="button html-dom-modal__actions-button" onClick={handleJoin} testId="cta-modal__join-now">
            <Translate as="span" resourceKey="cta.join" />
          </Button>
          <div className="inset-m" />
        </div>
      </Modal>
    </div>
  );
};

export default BenefitsLearnMore;
