import React from 'react';
import styles from './Disclaimer.module.scss';
import useParagraphs from 'src/hooks/useParagraphs';

type Props = {
  resource: string
}

export const Disclaimer: React.FC<Props> = ({ resource }) => {
  const paragraphs = useParagraphs(resource);

  return (
    <div className={styles.siteDisclaimer}>
      {paragraphs.map((paragraph, i) => (
        <p key={i}>
          {paragraph.map(({ text, href }, index) =>
            href ? (
              <a href={href} key={index} dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
              <span key={index} dangerouslySetInnerHTML={{ __html: text }} />
            )
          )}
        </p>
      ))}
    </div>
  );
};