import React from 'react';

import styles from '../FreeTrial.module.scss';

import useFreeTrialAssets from '../useFreeTrialAssets';
import FadeIn from 'src/components/FadeIn';
import { ResponsiveBackgroundImage } from 'src/components/ResponsiveBackgroundImage';
import { JoinCta } from 'src/components/JoinCta';

export const FreeTrialBanner: React.FC = () => {
  const assets = useFreeTrialAssets();
  const { banner } = assets;

  return (
    <div data-cy="free-trial-banner">
      <FadeIn duration={0.3}>
        <ResponsiveBackgroundImage
          className={styles.freeTrialBanner__background}
          desktopUrl={banner.images[0].url}
          tabletUrl={banner.images[1].url}
          mobileUrl={banner.images[2].url}
        >
          <div className="container container_center">
            <FadeIn duration={0.4} up>
              <div className={styles.freeTrialBanner__copy}>
                <h2 className={`${styles.freeTrialBanner__title} no-padding`} data-cy="free-trial-banner-title">
                  <span className={styles.freeTrialunderline}>{banner.title['em-0']}</span>
                  <span>{banner.title['text-1']}</span>
                  <span className={styles.freeTrialunderline}>{banner.title['em-2']}</span>
                  <span className={styles.freeTrialunderline}>{banner.title['text-3']}</span>
                </h2>
                <h3
                  className={`${styles.freeTrialBanner__description} no-padding`}
                  data-cy="free-trial-banner-description"
                >
                  {banner.description}
                </h3>
                <JoinCta
                  className={`${styles.freeTrialLink} ${styles.freeTrialBanner__link} button button__gradient`}
                  testid="join-membership"
                  disclaimerClassName={styles.freeTrialBanner__disclaimer}
                  disclaimerTestId="free-trial-banner-disclaimer"
                />
              </div>
            </FadeIn>
          </div>
        </ResponsiveBackgroundImage>
      </FadeIn>
    </div>
  );
};
