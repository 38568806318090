import React from 'react';
import styles from './DownArrow.module.scss';

import { DownArrowProps } from './DownArrow.types';

export const DownArrow: React.FC<DownArrowProps> = ({ className, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const arrow = (
    <div className="position-absolute-center">
      <div className={styles.downArrow__arrowContainer}>
        <div className={`${styles.downArrow__arrow} position-absolute-center`}>
          <div className={styles.downArrow__arrowLine}></div>
          <div className={styles.downArrow__arrowPointer}></div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`${styles.downArrow} ${className}`} data-cy="down-arrow">
      <div className={styles.downArrow__line}></div>
      {onClick ? (
        <button
          className={`${styles.downArrow__circle} ${styles.downArrow__button}`}
          onClick={handleClick}
          data-cy="down-arrow-button"
        >
          {arrow}
        </button>
      ) : (
        <div className={styles.downArrow__circle} onClick={handleClick}>
          {arrow}
        </div>
      )}
    </div>
  );
};
