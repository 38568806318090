import marked from 'marked';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import linkVariables from 'src/helpers/linkVariables';

export type Element = {
  text: string;
  href?: string;
};

export const parseParagraphs = (markdown: string) => {
  const paragraphs = [];
  const tokens = marked.lexer(markdown);
  for (const token of tokens) {
    if (token.type === 'paragraph') {
      const p = token.tokens
        .filter((t) => t.type === 'text' || t.type === 'link')
        .map((t) => {
          const element: Element = {
            text: t['text'],
          };
          if (t['href']) {
            element.href = t['href'];
          }
          return element;
        }) as Element[];
      paragraphs.push(p);
    }
  }
  return paragraphs;
};

const useParagraphs = (resourceKey: string) => {
  const { t, ready } = useTranslation();
  return useMemo(() => {
    const markdown = t(resourceKey, linkVariables());
    return parseParagraphs(markdown);
  }, [ready, resourceKey]);
};

export default useParagraphs;
