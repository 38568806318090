import { useEffect, useState } from 'react';

const useBodyClassInjector = () => {
  const [injectedClasses, setInjectedClasses] = useState<string[]>(null);

  useEffect(() => {
    if (injectedClasses) {
      document.body.classList.add(...injectedClasses);
    }

    return () => {
      if (injectedClasses) {
        document.body.classList.remove(...injectedClasses);
      }
    };
  }, [injectedClasses]);

  const appendBodyClasses = (classes: string[]) => {
    setInjectedClasses((prevState) => {
      if (prevState) {
        return [...prevState, ...classes];
      }

      return classes;
    });
  };

  const setBodyClasses = (classes: string[]) => {
    setInjectedClasses(classes);
  };

  const clearBodyClasses = () => {
    document.body.classList.remove(...injectedClasses);

    setInjectedClasses(null);
  };

  return { appendBodyClasses, setBodyClasses, clearBodyClasses };
};

export default useBodyClassInjector;
