import React from 'react';
import { Disclaimer } from './Disclaimer';

type Props = {
  hasSavings?: boolean;
};

export const SiteDisclaimer: React.FC<Props> = ({ hasSavings = false }) => {
  const resource = hasSavings ? 'site-disclaimer.savings' : 'site-disclaimer.fullSingleTier';

  return <Disclaimer resource={resource} />;
};
