import React from 'react';
import { withPrefix } from 'gatsby';
import FadeIn from 'src/components/FadeIn';

export const BenefitsMagazine = () => {
  return (
    <div className="benefits-page__magazine-main-part">
      <div className="benefits-page__magazine-main-inner">
        <figure>
          <FadeIn duration={0.3}>
            <img alt="magazine" className="benefits-page__image" src={withPrefix('/magazine_top_left.jpg')} />
          </FadeIn>
        </figure>
        <figure>
          <FadeIn duration={0.3}>
            <img alt="magazine" className="benefits-page__image" src={withPrefix('/magazine_top_right.jpg')} />
          </FadeIn>
        </figure>
        <figure>
          <FadeIn duration={0.3}>
            <img alt="magazine" className="benefits-page__image" src={withPrefix('/magazine_main.jpg')} />
          </FadeIn>
        </figure>
      </div>
    </div>
  );
};
